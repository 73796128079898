import { useEffect, useRef } from 'react'
import { usePage } from '@inertiajs/react'
import { toast } from 'react-toastify'
import isString from 'lodash/isString'
import 'react-toastify/dist/ReactToastify.css'
import type { ToastContent, ToastOptions } from 'react-toastify/dist/types'

export default function InertiaFlashMessage() {
    const shouldSkipFlashMessage = useRef(true)

    window.onpopstate = () => {
        shouldSkipFlashMessage.current = false
    }

    const { flash } = usePage().props

    useEffect(() => {
        if (!flash) {
            return
        }
        if (!shouldSkipFlashMessage.current) {
            shouldSkipFlashMessage.current = true
            return
        }

        const toastFnType: Record<string, (content: ToastContent, options?: ToastOptions | undefined) => void> = {
            success: toast.success,
            error: toast.error,
            warning: toast.warning,
            info: toast.info,
        }

        Object.entries(flash).forEach(([type, message]) => {
            if (isString(message)) {
                const toastFn = toastFnType?.[type] ?? toast
                toastFn(message)
            }
        })
    }, [flash])

    return null
}
