import type { PropsWithChildren } from 'react'
import React from 'react'
import { createTeleporter } from 'react-teleporter'

const MenuUserTeleporter = createTeleporter({ multiSources: true })

export function MenuUserTarget() {
    return <MenuUserTeleporter.Target />
}

export function MenuUserSource({ children }: PropsWithChildren) {
    return <MenuUserTeleporter.Source>{children}</MenuUserTeleporter.Source>
}
