import locale from 'antd/lib/locale/fr_FR'
import merge from 'lodash/merge'

const overrides = merge(locale, {
    DatePicker: {
        lang: {
            shortWeekDays: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
            shortMonths: [
                'Janvier',
                'Février',
                'Mars',
                'Avril',
                'Mai',
                'Juin',
                'Juillet',
                'Aout',
                'Septembre',
                'Octobre',
                'Novembre',
                'Décembre',
            ],
        },
    },
})
export default overrides
