//@ts-expect-error imported with vite alias
import Router from 'ziggy/Router'
import { usePage } from '@inertiajs/react'

export default function useZiggy() {
    const { routes } = usePage().props

    const ziggyRoute = (name?: string, params?: object, absolute = false) => {
        const router = new Router(name, params, absolute, routes)
        return name ? router.toString() : router
    }

    const getRoute = (name?: string, params: object = {}): string => {
        if (!name) {
            return ''
        }
        try {
            return ziggyRoute(name, params)
        } catch (e) {
            console.warn(`route "${name}" does not exists`)
            return ''
        }
    }

    return { ziggyRoute, getRoute }
}
