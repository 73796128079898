import { Modal, Typography } from 'antd'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { router, usePage } from '@inertiajs/react'
import useZiggy from '@/hooks/useZiggy'
import { ReactComponent as IconWelcome } from '@assets/icon/welcome.svg'

const GcuModal = () => {
    const [open, setOpen] = useState(true)
    const { t } = useTranslation('popup')
    const { getRoute } = useZiggy()
    const { user } = usePage().props

    const handleOk = () => {
        router.post(getRoute('user.accept-gcu'))
        setOpen(false)
    }

    const handleCancel = () => {
        window.open(t('footer:links.cgu'), '_blank', 'noreferrer')
    }

    if (user?.current_gcu_accepted) {
        return null
    }

    const mailto = 'mailto:' + t('popup:gcu.text.email')

    return (
        <>
            <Modal
                open={open}
                onOk={handleOk}
                closable={false}
                okText={t('popup:gcu.okText')}
                cancelText={t('popup:gcu.cancelText')}
                onCancel={handleCancel}
                css={(theme) => ({
                    top: 150,
                    '&.ant-modal .ant-modal-footer': {
                        textAlign: 'center',
                        paddingTop: 40,
                        paddingBottom: 20,
                        'button.ant-btn': {
                            fontSize: '16px',
                            height: '50px',
                            padding: '12px 15px',
                            borderRadius: '999px',
                            paddingInlineStart: '24px',
                            paddingInlineEnd: '24px',
                        },
                    },
                    '&.ant-modal .ant-modal-content': {
                        backgroundColor: 'transparent',
                        boxShadow: 'unset',
                        paddingLeft: 60,
                        paddingRight: 60,
                    },
                    '&.ant-modal .ant-modal-header': { backgroundColor: 'transparent' },
                    borderRadius: '10px',
                    width: '90% !important',
                    maxWidth: 940,
                    backgroundColor: theme.colors.form.main,
                })}
                title={
                    <>
                        <div
                            css={(theme) => ({
                                width: 112,
                                height: 112,
                                borderRadius: 27,
                                backgroundColor: theme.colors.disabled.contrastText,
                                margin: '0 auto 0px auto',
                                transform: 'translateY(-50px)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                '& > svg': {
                                    height: 74,
                                    color: theme.colors.accent.lightPeriwinkle,
                                },
                            })}
                        >
                            <IconWelcome />
                        </div>
                        <Typography.Title
                            css={() => ({
                                textAlign: 'center',
                                fontSize: '39px !important',
                                marginTop: '-30px !important',
                                marginBottom: '15px !important',
                                textTransform: 'capitalize',
                                color: 'white !important',
                            })}
                        >
                            {t('popup:gcu.title')}
                        </Typography.Title>
                        <Typography.Title
                            level={2}
                            css={{
                                textAlign: 'left',
                                fontSize: '25px !important',
                                marginTop: '0 !important',
                                color: 'white !important',
                            }}
                        >
                            {t('popup:gcu.subtitle')}
                        </Typography.Title>
                    </>
                }
            >
                <Typography.Paragraph css={(theme) => ({ fontSize: '15px', color: theme.colors.header.contrastText })}>
                    {t('popup:gcu.text.firstPart')}
                </Typography.Paragraph>
                <Typography.Paragraph css={(theme) => ({ fontSize: '15px', color: theme.colors.header.contrastText })}>
                    {t('popup:gcu.text.secondPart')}
                </Typography.Paragraph>
                <Typography.Paragraph css={(theme) => ({ fontSize: '15px', color: theme.colors.header.contrastText })}>
                    {t('popup:gcu.text.thirdPart')}
                </Typography.Paragraph>
                <Typography.Paragraph css={(theme) => ({ fontSize: '15px', color: theme.colors.header.contrastText })}>
                    <Trans
                        i18nKey="popup:gcu.text.fourthPart"
                        components={{
                            Mailto: <a href={mailto} target="_blank" rel="noreferrer" />,
                        }}
                    />
                </Typography.Paragraph>
                <Typography.Paragraph css={(theme) => ({ fontSize: '15px', color: theme.colors.header.contrastText })}>
                    {t('popup:gcu.text.fifthPart')}
                </Typography.Paragraph>
                <Typography.Paragraph css={(theme) => ({ fontSize: '15px', color: theme.colors.header.contrastText })}>
                    {t('popup:gcu.text.salutation')}
                </Typography.Paragraph>
            </Modal>
        </>
    )
}

export default GcuModal
