import locale from 'antd/lib/locale/en_US'
import merge from 'lodash/merge'

const overrides = merge(locale, {
    DatePicker: {
        lang: {
            shortWeekDays: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
            shortMonths: [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
            ],
        },
    },
})
export default overrides
