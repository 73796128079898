import { css } from '@emotion/react'
import theme from '@/styles/theme'

export default css`
    .ant-segmented {
        border: solid 1px ${theme.colors.common.white};
        padding: 0;
    }

    .ant-segmented .ant-segmented-thumb,
    .ant-segmented .ant-segmented-item {
        border-radius: 999px;
        text-transform: uppercase;
        font-size: 13px;
    }

    .ant-segmented .ant-segmented-item-selected {
        color: ${theme.colors.common.black};
    }
`
