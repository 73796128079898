import usePageUrl from '@/hooks/usePageUrl'
import { useMemo } from 'react'
import { ProjectSpaceTypeEnum } from '@/types/Project.type'

const COMPANY_SEGMENT_URL = 'companies' as const
const useProjectSpace = () => {
    const { pageUrl } = usePageUrl()

    const companyIndex: number | undefined = useMemo(() => {
        return pageUrl.pathname.split('/')?.indexOf(COMPANY_SEGMENT_URL)
    }, [pageUrl.pathname])

    const currentSpace = useMemo(() => {
        if (!companyIndex) {
            return
        }
        return companyIndex >= 1 ? ProjectSpaceTypeEnum.Company : ProjectSpaceTypeEnum.User
    }, [companyIndex])

    const currentCompanyId = useMemo(() => {
        if (!companyIndex) {
            return
        }
        if (companyIndex >= 1) {
            return pageUrl.pathname.split('/')?.[companyIndex + 1]
        }
        return undefined
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyIndex])

    return { currentSpace, currentCompanyId }
}

export default useProjectSpace
