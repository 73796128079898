import { css } from '@emotion/react'
import theme from '@/styles/theme'

export default css`
    .ant-input-number-group .ant-input-number-group-addon,
    .ant-input-group .ant-input-group-addon {
        background-color: ${theme.colors.disabled.light};
        font-size: 14px;
        font-weight: 700;
    }

    .ant-picker,
    .ant-input-number-group-wrapper,
    .ant-input-number-in-form-item {
        width: 100%;
    }

    .ant-input-number-group .ant-input-number-group-addon .ant-select-open .ant-select-selector,
    .ant-input-number-group .ant-input-number-group-addon .ant-select-focused .ant-select-selector {
        color: ${theme.colors.common.black};
    }

    .ant-input-number .ant-input-number-handler-up-inner,
    .ant-input-number .ant-input-number-handler-down-inner {
        color: ${theme.colors.common.black};
    }

    .ant-input-number-disabled .ant-input-number-input,
    .ant-input-number-readonly .ant-input-number-input {
        color: #19171c;
    }
`
