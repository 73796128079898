import React from 'react'
import { Avatar, Button, Dropdown, Space } from 'antd'
import { router, usePage } from '@inertiajs/react'
import useZiggy from '@/hooks/useZiggy'
import { CaretDownFilled } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

const LocaleSwitch = () => {
    const { locale, available_locales } = usePage().props
    const { getRoute } = useZiggy()
    const { i18n } = useTranslation()

    const handleLocaleChange = (value: string) => {
        router.get(getRoute('switch_lang', { locale: value }))
        i18n.changeLanguage(value)
    }

    return (
        <Dropdown
            trigger={['hover']}
            dropdownRender={() => {
                return (
                    <Space direction="vertical" css={{ width: '100%', alignItems: 'center' }}>
                        {available_locales.map((locale) => (
                            <Button
                                key={locale.value}
                                type="primary"
                                shape="circle"
                                onClick={() => handleLocaleChange(String(locale.value))}
                                css={{ fontSize: 14 }}
                            >
                                {locale.value}
                            </Button>
                        ))}
                    </Space>
                )
            }}
        >
            <Avatar size="large" css={{ fontSize: 14, textTransform: 'uppercase', lineHeight: 1, cursor: 'pointer' }}>
                <Space direction="vertical" size={0}>
                    {locale}
                    <CaretDownFilled />
                </Space>
            </Avatar>
        </Dropdown>
    )
}

export default LocaleSwitch
