import { css } from '@emotion/react'
import theme from '@/styles/theme'

export default css`
    .ant-pagination-item a {
        text-decoration: none;
    }

    .ant-pagination .ant-pagination-item {
        background-color: #0a090b;
    }

    .ant-pagination .ant-pagination-item-active {
        background-color: ${theme.colors.common.white};
        border-color: ${theme.colors.common.white};
    }
    .ant-pagination .ant-pagination-item-active a,
    .ant-pagination .ant-pagination-item-active a:hover {
        color: ${theme.colors.common.black};
    }
`
