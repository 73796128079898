import React from 'react'
import { Space, Typography } from 'antd'
import { spacing } from '@/styles/theme'
import { useTranslation } from 'react-i18next'

const FooterLinks = () => {
    const { t } = useTranslation(['common', 'footer'])

    return (
        <Space size={spacing(2)} css={{ fontSize: 13 }}>
            <Typography.Text css={{ color: 'inherit', fontSize: '1em' }}>
                © Ecoprod {new Date().getFullYear()}
            </Typography.Text>
            <a target={'_blank'} href={t('footer:links.cgu')} rel="noreferrer">
                {t('common:footer.cgu')}
            </a>
            <a target={'_blank'} href={t('footer:links.privacy')} rel="noreferrer">
                {t('common:footer.privacy')}
            </a>
            <a target={'_blank'} href={t('footer:links.contact')} rel="noreferrer">
                {t('common:footer.contact')}
            </a>
        </Space>
    )
}

export default FooterLinks
