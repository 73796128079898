import React from 'react'
import { useTranslation } from 'react-i18next'
import { Space } from 'antd'
import { spacing } from '@/styles/theme'
import { ReactComponent as IconLinkedin } from '@assets/icon/linkedin.svg'
import { ReactComponent as IconWebsite } from '@assets/icon/website.svg'
import { ReactComponent as IconInstagram } from '@assets/icon/instagram.svg'

const FooterSocials = () => {
    const { i18n } = useTranslation('auth')

    return (
        <Space size={spacing(1)}>
            <a
                target={'_blank'}
                href={`https://ecoprod.com/${i18n.language === 'en' ? i18n.language : ''}`}
                rel="noreferrer"
            >
                <IconWebsite />
            </a>
            <a target={'_blank'} href="https://www.linkedin.com/company/ecoprod/" rel="noreferrer">
                <IconLinkedin />
            </a>
            <a target={'_blank'} href="https://www.instagram.com/ecoprodfrance/" rel="noreferrer">
                <IconInstagram />
            </a>
        </Space>
    )
}

export default FooterSocials
