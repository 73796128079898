import { css } from '@emotion/react'
import theme from '../theme'

export default css`
    .ant-anchor-link-parent > a {
        text-transform: uppercase;
    }
    .ant-anchor-wrapper .ant-anchor .ant-anchor-link-parent {
        border-bottom: 1px solid ${theme.colors.common.midGray};
        padding-block: 12px;
    }
    .ant-anchor-wrapper .ant-anchor .ant-anchor-link-parent:has(.ant-anchor-link-active),
    .ant-anchor-wrapper .ant-anchor .ant-anchor-link-parent.ant-anchor-link-active {
        padding-block: 8px;
        background-color: ${theme.colors.primary.contrastText};
        border: none;
        border-radius: 0.75rem;
    }
    .ant-anchor-link-title {
        text-decoration: none;
    }
    .ant-anchor-link-title.ant-anchor-link-active {
        display: list-item;
        list-style-type: disc;
    }
    .ant-anchor-ink {
        margin-left: 3px;
    }

    .ant-anchor-wrapper .ant-anchor .ant-anchor-link .ant-anchor-link {
        padding-block: 6px;
    }

    .ant-anchor-wrapper .ant-anchor .ant-anchor-link-title {
        overflow: auto;
        white-space: break-spaces;
        text-overflow: initial;
    }

    .content-scroll::-webkit-scrollbar {
        display: none;
    }
`
