import { usePage } from '@inertiajs/react'
import isEqual from 'lodash/isEqual'
import type { ParsedQs } from 'qs'
import { parse } from 'qs'

import { useEffect, useMemo, useRef } from 'react'

const decoder = (value: string) => {
    if (/^(\d+|\d*\.\d+)$/.test(value)) {
        return Number(value)
    }

    const keywords: Record<string, unknown> = {
        true: true,
        false: false,
        null: null,
        undefined: undefined,
    }
    if (value in keywords) {
        return keywords[value]
    }

    return decodeURI(value)
}

export default function usePageUrl() {
    const pageUrl = new URL(usePage().url, window.location.origin)
    const searchParams = useMemo<ParsedQs>(() => {
        return parse(pageUrl.search, { ignoreQueryPrefix: true, decoder }) as ParsedQs
    }, [pageUrl.search])
    const searchParamsRef = useRef<ParsedQs>({})

    useEffect(() => {
        searchParamsRef.current = searchParams
    }, [searchParams])

    // unknown and never do not work here
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const setSearchParams = (name: string, value?: any, defaultValue?: any) => {
        if (!isEqual(value, defaultValue)) {
            searchParamsRef.current[name] = value
        } else if (searchParamsRef.current?.[name]) {
            delete searchParamsRef.current[name]
        }
    }

    return {
        pageUrl,
        searchParams,
        searchParamsRef,
        setSearchParams,
    }
}
