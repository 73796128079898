import React from 'react'
import { Result, Spin } from 'antd'
import { useTranslation } from 'react-i18next'

const LoadingPage = (): React.ReactElement => {
    const { t } = useTranslation('common')

    return <Result icon={<Spin size="large" />} title={t('common:loading.message')} />
}

export default LoadingPage
