import React, { Suspense, useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import { ConfigProvider } from 'antd'
import { ThemeProvider } from '@emotion/react'
import theme, { antTheme } from '@/styles/theme'
import ReloadPrompt from '@/components/toast/ReloadPrompt'
import InertiaFlashMessage from '@/components/toast/InertiaFlashMessage'
import { toast, ToastContainer } from 'react-toastify'
import { I18nextProvider } from 'react-i18next'
import i18n from '@/translations/i18n'
import antFr from '@/translations/fr/antd'
import antEn from '@/translations/en/antd'
import dayjs from 'dayjs'
import dayjsFr from 'dayjs/locale/fr'
import dayjsEn from 'dayjs/locale/en'
import { usePage } from '@inertiajs/react'
import type { Locale as AntLocal } from 'antd/es/locale'
import type { Locale as DayjsLocale } from 'dayjs/locale/*'
import HintProvider from '@/providers/HintProvider'
import ErrorPage from '@/components/feedback/ErrorPage'
import LoadingPage from '@/components/feedback/LoadingPage'

const ConfigProviders = ({ children }: React.PropsWithChildren): React.ReactElement => {
    const { locale } = usePage().props
    const [prevLocaleState, setPrevLocaleState] = useState<undefined | string>(undefined)

    useEffect(() => {
        if (!prevLocaleState && prevLocaleState !== locale) {
            setPrevLocaleState(locale)
            i18n.changeLanguage(locale)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locale])

    const antLocales: Record<string, AntLocal> = {
        fr: antFr,
        en: antEn,
    }

    const dayjsLocales: Record<string, DayjsLocale> = {
        fr: dayjsFr,
        en: dayjsEn,
    }

    dayjs.locale(dayjsLocales?.[locale])

    return (
        <Sentry.ErrorBoundary fallback={(props) => <ErrorPage status={500} {...props} />}>
            <I18nextProvider i18n={i18n}>
                <ThemeProvider theme={theme}>
                    <ConfigProvider theme={antTheme} locale={antLocales?.[locale]}>
                        <Suspense fallback={<LoadingPage />}>
                            <HintProvider>
                                {children}
                                <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} autoClose={5000} />
                                <InertiaFlashMessage />
                                <ReloadPrompt />
                            </HintProvider>
                        </Suspense>
                    </ConfigProvider>
                </ThemeProvider>
            </I18nextProvider>
        </Sentry.ErrorBoundary>
    )
}

export default ConfigProviders
