import React from 'react'
import { Button, Result } from 'antd'
import { stripIndents } from 'common-tags'
import { Link } from '@inertiajs/react'
import type { ResultStatusType } from 'antd/es/result'
import { useTranslation } from 'react-i18next'

export type ErrorProps = {
    status: ResultStatusType
    error: Error
    componentStack?: string
}
const ErrorPage = ({ status, error, componentStack }: ErrorProps) => {
    const { t } = useTranslation('common')

    const isDevError = import.meta.env.VITE_APP_ENV !== 'production' && status === 500
    const errorTrace = isDevError && componentStack && (
        <pre
            css={{
                fontSize: '0.7rem',
                textAlign: 'left',
                maxWidth: 800,
                marginInlineEnd: 'auto !important',
                marginInlineStart: 'auto !important',
            }}
        >
            {stripIndents(componentStack)}
        </pre>
    )

    return (
        <Result
            status={status}
            title={status}
            subTitle={isDevError ? error.toString() : t(`common:error.${status}`)}
            extra={
                <>
                    {errorTrace}
                    <Link href="/">
                        <Button type="primary">{t('common:error.action.homePage')}</Button>
                    </Link>
                </>
            }
        />
    )
}

export default ErrorPage
