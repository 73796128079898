import React from 'react'
import { Space, Typography } from 'antd'
import { spacing } from '@/styles/theme'
import { useTranslation } from 'react-i18next'
import LogoEcoprod from '@assets/logo/ecoprod.png?url'

const FooterLinks = () => {
    const { t } = useTranslation()

    return (
        <Space size={spacing(2)}>
            <Typography.Text type="secondary" css={{ textTransform: 'uppercase' }}>
                {t('common:footer.shipped')}
            </Typography.Text>
            <img src={LogoEcoprod} alt="Ecoprod" height={46} />
        </Space>
    )
}

export default FooterLinks
