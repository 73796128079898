import { css } from '@emotion/react'

export default css`
    a,
    .ant-typography a {
        color: inherit;
        text-decoration: underline;
        text-underline-offset: 0.33em;
    }

    .ant-btn.ant-btn-link {
        text-transform: initial;
        color: inherit;
    }

    .ant-btn.ant-btn-link > span {
        text-decoration: underline;
        text-underline-offset: 0.33em;
    }

    .ant-tabs-tab-btn > span {
        display: flex;
        -webkit-text-decoration: none;
        text-decoration: none;
        color: #eff0ef;
    }

    .ant-tabs-tab-btn > span svg {
        height: 16px;
        width: 16px;
        margin-right: 10px;
    }
`
