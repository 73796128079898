import { css } from '@emotion/react'

export default css`
    @font-face {
        font-family: 'Gilroy';
        src:
            url('/font/gilroy-medium-webfont.woff2') format('woff2'),
            url('/font/gilroy-medium-webfont.woff') format('woff');
        font-weight: 500;
        font-style: normal;
    }

    @font-face {
        font-family: 'Gilroy';
        src:
            url('/font/gilroy-bold-webfont.woff2') format('woff2'),
            url('/font/gilroy-bold-webfont.woff') format('woff');
        font-weight: 700;
        font-style: normal;
    }

    @font-face {
        font-family: 'Gilroy';
        src:
            url('/font/gilroy-semibold-webfont.woff2') format('woff2'),
            url('/font/gilroy-semibold-webfont.woff') format('woff');
        font-weight: 600;
        font-style: normal;
    }
`
