import React, { createContext, useRef } from 'react'

type HintState = {
    getGroup: (tooltip: string) => string[] | null
    getRef: (tooltip: string) => HTMLElement | null
    setRef: (element: HTMLElement | null, tooltip: string | string[]) => void
}

const defaultState: HintState = {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getGroup(tooltip: string): string[] | null {
        return null
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getRef(tooltip: string): HTMLElement | null {
        return null
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setRef(element: HTMLElement | null, tooltip: string | string[]): void {},
}

export const HintContext = createContext(defaultState)

const HintProvider = ({ children }: React.PropsWithChildren) => {
    const refs = useRef<Record<string, HTMLElement | null>>({})
    const groups = useRef<Record<string, string[]>>({})

    const getRef = (tooltip: string): HTMLElement | null => {
        return refs.current[tooltip]
    }
    const getGroup = (tooltip: string): string[] | null => {
        return groups.current[tooltip]
    }

    const setRef = (element: HTMLElement | null, tooltip: string | string[]) => {
        if (!Array.isArray(tooltip)) {
            tooltip = [tooltip]
        }
        tooltip.forEach((k) => {
            refs.current[k] = element
            groups.current[k] = tooltip as string[]
        })
    }

    return (
        <HintContext.Provider
            value={{
                getGroup,
                getRef,
                setRef,
            }}
        >
            {children}
        </HintContext.Provider>
    )
}

export default HintProvider
