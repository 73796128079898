import { css } from '@emotion/react'

export default css`
    h1.ant-typography,
    h3.ant-typography {
        text-transform: uppercase;
    }
    .title_label label {
        font-size: 18px !important;
        font-weight: 600;
    }
`
