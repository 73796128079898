import React from 'react'
import * as Sentry from '@sentry/react'
import { createRoot, hydrateRoot } from 'react-dom/client'
import { createInertiaApp } from '@inertiajs/react'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import BaseLayout from './layouts/BaseLayout'
import 'antd/dist/reset.css'

interface ModuleType {
    default: {
        layout: object
    }
}

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: [import.meta.env.VITE_APP_ENV, 'front'].join('-'),
    release: import.meta.env.SENTRY_RELEASE?.id,
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE,
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
})

createInertiaApp({
    resolve: async (name) => {
        const module = (await resolvePageComponent(
            `./pages/${name}.tsx`,
            import.meta.glob('./pages/**/*.tsx')
        )) as ModuleType
        const page = module.default
        page.layout ??= (page: React.ReactNode) => <BaseLayout children={page} />
        return page
    },
    setup({ el, App, props }) {
        if (import.meta.env.MODE === 'production') {
            hydrateRoot(el, <App {...props} />)
        } else {
            createRoot(el).render(<App {...props} />)
        }
    },
})
