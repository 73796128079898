import type { PropsWithChildren, ReactElement } from 'react'
import React from 'react'
import { Link } from '@inertiajs/react'
import useZiggy from '@/hooks/useZiggy'
import { Space, Typography } from 'antd'

interface MenuMainItemProps extends PropsWithChildren {
    icon: ReactElement
    title: string
    route: string
    routeParameters?: { [key: string]: unknown }
}

const MenuMainItem = ({ icon, title, children, route, routeParameters }: MenuMainItemProps) => {
    const { ziggyRoute, getRoute } = useZiggy()
    const selected = ziggyRoute().current() === route

    return (
        <Link
            href={getRoute(route, routeParameters ?? {})}
            css={(theme) => ({
                lineHeight: 1,
                color: selected ? theme.colors.common.white : theme.colors.header.contrastText,
                '&:hover': {
                    color: theme.colors.common.white,
                },
                '.anticon': {
                    fontSize: 32,
                },
                '.ant-typography': {
                    color: 'inherit',
                    textTransform: 'uppercase',
                    fontSize: 13,
                    fontWeight: 700,
                },
            })}
        >
            <Space direction="vertical" css={{ alignItems: 'center', textAlign: 'center' }}>
                {icon}
                <Typography.Text>{title}</Typography.Text>
                {children}
            </Space>
        </Link>
    )
}

export default MenuMainItem
