import styled from '@emotion/styled'
import { Layout, Menu as AntMenu } from 'antd'

export const Header = styled(Layout.Header)(({ theme }) => ({
    height: 'auto',
    paddingInline: theme.spacing(4) + 'px !important',
}))

export const Content = styled(Layout.Content)(({ theme }) => ({
    padding: theme.spacing(4),
}))

export const Menu = styled(AntMenu)({
    backgroundColor: 'transparent !important',
    borderInlineEnd: 'none !important',
})
