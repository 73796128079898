import { css } from '@emotion/react'
import theme from '../theme'

export default css`
    .ant-tabs > .ant-tabs-nav {
        background-color: ${theme.colors.header.main};
        margin-bottom: 0;
        &::before {
            border: none;
        }
    }
    .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active {
        border-radius: unset;
        background-color: ${theme.colors.accent.palePurple};
    }
    .ant-tabs-nav-list {
        width: 100%;
        justify-content: space-around;
    }
    .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
        border: none;
        padding-bottom: 20px;
        padding-top: 20px;
        flex: 1;
        text-align: center;
        background-color: transparent;
    }
    .ant-tabs-tab-btn {
        margin: auto;

        a {
            display: flex;
            text-decoration: none;
            color: ${theme.colors.common.white};

            & > svg {
                height: 16px;
                width: 16px;
                margin-right: 10px;
            }

            & > span {
                line-height: 16px;
            }
        }
    }
    .ant-tabs-tab-btn .ant-typography {
        white-space: normal;
        font-size: 14px;
    }
`
