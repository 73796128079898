import React from 'react'
import { Avatar, Dropdown, type MenuProps, Space } from 'antd'
import { Link, router, usePage } from '@inertiajs/react'
import useZiggy from '../../hooks/useZiggy'
import LocaleSwitch from '../i18n/LocaleSwitch'
import { useTranslation } from 'react-i18next'
import { MenuUserTarget } from '@/teleporters/MenuUserTeleporter'

const MenuUser = () => {
    const { t } = useTranslation('common')
    const { getRoute } = useZiggy()
    const { user } = usePage().props
    const items: MenuProps['items'] = [
        {
            key: 'profile',
            label: <Link href={getRoute('user.account')}>{t('common:header.menuUser.profile')}</Link>,
        },
        {
            type: 'divider',
        },
        {
            key: 'tokens',
            label: <Link href={getRoute('user.tokens.index')}>{t('common:header.menuUser.tokens')}</Link>,
        },
        {
            type: 'divider',
        },
        {
            key: 'logout',
            label: t('common:header.menuUser.logout'),
            onClick: () => router.post(getRoute('logout')),
        },
    ]

    return (
        <Space>
            <MenuUserTarget />
            <LocaleSwitch />
            {user ? (
                <Dropdown menu={{ items }} trigger={['hover']}>
                    <Avatar
                        size="large"
                        css={(theme) => ({
                            backgroundColor: theme.colors.accent.babyPink,
                            cursor: 'pointer',
                            '.ant-avatar-string': {
                                fontSize: 22,
                                textTransform: 'uppercase',
                            },
                        })}
                    >
                        {user.first_name.substring(0, 1)}
                    </Avatar>
                </Dropdown>
            ) : null}
        </Space>
    )
}

export default MenuUser
