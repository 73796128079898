import { css } from '@emotion/react'
import theme from '../theme'

export default css`
    [class^='ant-table'],
    .ant-table-wrapper .ant-table {
        font-size: 14px;
    }

    .ant-table-filter-dropdown {
        position: relative;
        overflow: visible !important;
    }

    .ant-table-wrapper .ant-table-filter-trigger {
        color: ${theme.colors.disabled.light};
    }
`
