import { css } from '@emotion/react'
import theme from '../theme'

export default css`
    .ant-alert {
        text-align: left;
    }

    .ant-alert-message {
        color: ${theme.colors.common.black};
    }

    .ant-alert.ant-alert-info {
        background-color: ${theme.colors.info.main};
        border: none;
        color: ${theme.colors.info.contrastText};
    }

    .ant-alert.ant-alert-info .ant-alert-icon {
        color: ${theme.colors.info.contrastText};
    }
`
