import React, { useEffect } from 'react'
import { toast } from 'react-toastify'
import { Button, Space } from 'antd'
import { useRegisterSW } from 'virtual:pwa-register/react'

const refreshIntervalMs = 60 * 60 * 1000 // 1h

const ReloadPrompt = () => {
    const {
        needRefresh: [needRefresh, setNeedRefresh],
        updateServiceWorker,
    } = useRegisterSW({
        onRegisteredSW(swScriptUrl: string, registration: ServiceWorkerRegistration | undefined) {
            if (!registration) {
                return
            }
            setInterval(() => {
                registration.update()
            }, refreshIntervalMs)
        },
        onRegisterError(error) {
            console.error('SW registration error', error)
        },
    })

    const handleClose = () => {
        setNeedRefresh(false)
    }

    const handleUpdate = () => {
        updateServiceWorker(true).then(() => window.location.reload())
    }

    const Toast = () => {
        return (
            <Space direction="vertical">
                Une mise à jour de l’application est disponible
                <Button type="primary" size="small" onClick={handleUpdate}>
                    Recharger
                </Button>
            </Space>
        )
    }

    useEffect(() => {
        if (needRefresh) {
            toast(<Toast />, {
                toastId: 'ReloadPrompt',
                autoClose: false,
                onClose: handleClose,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [needRefresh])

    return null
}

export default ReloadPrompt
