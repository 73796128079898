import { css } from '@emotion/react'
import theme from '@/styles/theme'

export default css`
    .ant-select-dropdown {
        .ant-select-item-group {
            color: ${theme.colors.disabled.contrastText};
            font-weight: 700;
            font-size: 17px;
        }
        .ant-select-item-option-grouped {
            padding-inline-start: 40px;
        }
        .ant-select-item {
            min-height: 20px !important;
            padding-top: 2px !important;
            padding-bottom: 2px !important;
        }
    }

    .ant-select-multiple .ant-select-selection-item {
        border-radius: 999px;
    }

    .ant-select-dropdown .ant-empty-description {
        color: ${theme.colors.common.black};
    }
    .ant-select:hover .ant-select-clear {
        font-size: 20px;
        height: 20px;
        margin-top: -10px;
        right: 14px;
        :hover {
            color: ${theme.colors.common.black};
        }
    }
`
