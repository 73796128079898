import React from 'react'
import { Col, Layout, Row } from 'antd'
import * as Styles from './BaseLayout.styles'
import ConfigProviders from '@/providers/ConfigProviders'
import { spacing } from '@/styles/theme'
import MenuMain from '@/components/header/MenuMain'
import MenuUser from '@/components/header/MenuUser'
import GlobalStyles from '@/styles/GlobalStyles'
import FooterLinks from '@/components/footer/FooterLinks'
import FooterSocials from '@/components/footer/FooterSocials'
import FooterShipped from '@/components/footer/FooterShipped'
import OnBoardingModal from '@/pages/OnBoarding/OnBoardingModal'
import GcuModal from '@/pages/Gcu/GcuModal'

const BaseLayout = ({ children }: React.PropsWithChildren): React.ReactElement => {
    return (
        <ConfigProviders>
            <GlobalStyles />
            <Layout>
                <Styles.Header>
                    <Row justify="space-between" align="middle" wrap={false}>
                        <Col>
                            <MenuMain />
                        </Col>
                        <Col>
                            <MenuUser />
                        </Col>
                    </Row>
                </Styles.Header>
                <OnBoardingModal />
                <GcuModal />
                <Styles.Content css={{ marginBottom: spacing(2), marginTop: spacing(4) }}>{children}</Styles.Content>
                <Layout.Footer>
                    <Row
                        justify="space-between"
                        gutter={spacing(2)}
                        css={(theme) => ({ alignItems: 'center', color: theme.colors.header.contrastText })}
                    >
                        <Col>
                            <FooterShipped />
                        </Col>
                        <Col>
                            <FooterLinks />
                        </Col>
                        <Col>
                            <FooterSocials />
                        </Col>
                    </Row>
                </Layout.Footer>
            </Layout>
        </ConfigProviders>
    )
}

export default BaseLayout
